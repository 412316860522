enum cardCodes {
    code4 = 'media/svg/card-logos/visa.svg',
    code5 = 'media/svg/card-logos/mastercard.svg',
    code3 = 'media/svg/card-logos/american-express.svg',
}

enum cardTypeCodes {
    code4 = 'Visa',
    code5 = 'Mastercard',
    code3 = 'American Express',
}

const findIconCard = (code, enums) => {
    for (const [propertyKey, propertyValue] of Object.entries(enums)) {
        const number = propertyKey.slice(4);
        if (number == code){
            return propertyValue
        }
    }
}

export {cardCodes, cardTypeCodes, findIconCard}