
import { defineComponent, ref, onMounted, computed } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { cardCodes, cardTypeCodes, findIconCard } from "@/store/enums/cardCodes";

interface ICustomer {
  id: number;
  name: string;
  email: string;
  company: string;
  phone?: string;
  group?: string;
  count?: string;
  expired?: string;
  payment: {
    icon: string;
    ccnumber: string;
    label: string;
  };
  date: string;
}

export default defineComponent({
  name: "customers-listing",
  components: {
    Datatable,
    AddCustomerModal,
  },
  setup() {
    const checkedCustomers = ref([]);
    const store = useStore();

    store.dispatch(Actions.GET_SUBSCRIBERS_TABLE)

    const tableHeader = ref([
      {
        name: "Count",
        key: "count",
        sortable: true,
      },
      {
        name: "Group",
        key: "group",
        sortable: true,
      },
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Email",
        key: "email",
        sortable: true,
      },
      {
        name: "Phone",
        key: "phone",
        sortable: true,
      },
      {
        name: "Expired",
        key: "expired",
        sortable: true,
      },
      {
        name: "Payment Method",
        key: "paymentMethod",
        sortingField: "payment.label",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const getCustomers = computed(() => {
      const data = store.getters.subscribersTable
      const table = data.map((item, inx) => {
          const img = findIconCard(item.card_first6[0], cardCodes)
          return {
            id: item._id,
            count: inx + 1,
            name: item.user.fname,
            email: item.user.email,
            phone: item.user.phone,
            group: item.group_name,
            icon: img,
            expired: item.date_expired,
            payment: {
              ccnumber: item.card_last4,
              firstnumber: item.card_first6
            }
          }
      })
      return  table ? table : [];
    });

    const customers = ref<Array<ICustomer>>([])
    const initCustomers = ref<Array<ICustomer>>([]);

    onMounted(() => {
      MenuComponent.reinitialization();
      initCustomers.value.splice(0, customers.value.length, ...customers.value);

      setCurrentPageBreadcrumbs("Customers Listing", ["Apps", "Customers"]);
    });

    const deleteFewCustomers = () => {
      checkedCustomers.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCustomers.value.length = 0;
    };

    const deleteCustomer = (id) => {
      for (let i = 0; i < customers.value.length; i++) {
        if (customers.value[i].id === id) {
          customers.value.splice(i, 1);
        }
      }
    };

    const search = ref<string>("");
    const searchItems = () => {
      customers.value.splice(0, customers.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<ICustomer> = [];
        for (let j = 0; j < customers.value.length; j++) {
          if (searchingFunc(customers.value[j], search.value)) {
            results.push(customers.value[j]);
          }
        }
        customers.value.splice(0, customers.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      customers,
      getCustomers,
      tableHeader,
      deleteCustomer,
      search,
      searchItems,
      checkedCustomers,
      deleteFewCustomers,
    };
  },
});
